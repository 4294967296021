import { Box, SxProps } from '@mui/material';
import ReactPlayer from 'react-player/youtube';
import useResponsive from '#/hooks/useResponsive';

type VideoTutorialProps = {
  youtubeLink: string;
  sx?: SxProps;
  width?: number;
  height?: number;
};

export default function VideoTutorial({
  youtubeLink,
  sx,
  width,
  height,
}: VideoTutorialProps) {
  const isMobile = useResponsive('down', 'md');

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        width: 1,
        ...sx,
      }}
    >
      <ReactPlayer
        url={youtubeLink}
        width={width || (isMobile ? '100%' : 670)}
        height={height || (isMobile ? 200 : 400)}
      />
    </Box>
  );
}
